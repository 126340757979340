import './App.css';
import Hero from './components/Hero/hero.jsx';
import Plans from './components/Plans/Plans';
import Programs from './components/Program/programs';
import Reasons from './components/Reasons/Reasons';
function App() {
  return (
    <div className="App">
      <Hero />
      <Programs/>
      <Reasons/>
      <Plans/>
    </div>
  );
}

export default App;
