import React from 'react'
import { plansData } from "../../data/plansData";
import whitetick from '../../assets/whiteTick.png'
import './Plans.css'

const Plans = () => {
  return (
    <div className="plans-containers">
      <div className="plans-header" style={{ gap: "2rem" }}>
        <span className="stroke-text">READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className="stroke-text">NOW WITH US</span>
      </div>
      {/*Plans Card*/}
      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan">
            {plan.icon}
            <span>{plan.name}</span>
            <span>$ {plan.price}</span>

            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <img src={whitetick} alt="" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            <div><span>See more benefits</span></div>
            <button>Join Now</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Plans