import React from "react";
import Header from "../Header/Header";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";



import './hero.css'

const Hero = () =>{
    return (
      <>
        <div className="hero">
          <div className="left-hero">
            <Header />
            {/*the best ad*/}
            <div className="the-best-ad">
              <span className="span">the best fitness club in town</span>
              <div></div>
            </div>
            {/*Hero Heading*/}

            <div className="hero-text">
              <div>
                <span className="stroke-text">Shape </span>
                <span>Your</span>
              </div>
              <div>
                <span>Ideal body</span>
              </div>
              <div>
                <span>
                  In here we will help you to shape and build your ideal
                </span>
              </div>
            </div>
            {/*Figures*/}
            <div className="figures">
              <div>
                <span>+140</span>
                <span>expect coaches</span>
              </div>
              <div>
                <span>+978</span>
                <span>members joined</span>
              </div>
              <div>
                <span>+50</span>
                <span>fit programs</span>
              </div>
            </div>
            {/*hero buttons */}
            <div className="hero-buttons">
              <button className="btn">Get Started</button>
              <button className="btn">Learn More</button>
            </div>
          </div>
          <div className="right-hero">
            <button className="btn">Join Now</button>
            <div className="heart-rate">
              <img src={Heart} alt="" />
              <span>Heart Rate</span>
              <span>116 bpm</span>
            </div>
            {/*Hero images*/}
            <img src={hero_image} alt="" className="hero-image" />
            <img src={hero_image_back} alt="" className="hero-image-back" />

            {/*Calories*/}
            <div className="calories">
              <img src={Calories} alt="" />
              <div>
                <span>Calories Burned</span>
                <span>220 cal</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Hero